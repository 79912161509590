@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3 {
  font-family: "Libre Baskerville", serif;
}

body {
  font-family: "Noto Sans", sans-serif;
}

.hero-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../src/assets/hero.jpg");
  background-size: cover;
  height: 101vh;
  background-position: 50% 92%;
}

#description {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../src/assets/plains.jpg");
  /* height: 80vh; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.texture-bg {
  background: url("../src/assets/white-waves.png");
  background-repeat: repeat;
}

.about-header-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../src/assets/blue-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.personal-service-image {
  max-height: 700px;
  border: 2px solid black;
  padding: 30px;
}

.nav-active {
  background-color: rgb(255, 255, 255, 0.2);
}

footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.hamburger .line {
  width: 50px;
  height: 5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.text-vertical {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  width: 100px;
}

.text-vertical-2 {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}
